import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import React from "react";

const HasMembershipAgreementSectionsOutstanding = ({
    hasMembershipAgreementSectionsOutstanding,
    hasMembershipAgreementSectionAmendmentOutstanding,
}: {
    hasMembershipAgreementSectionsOutstanding: boolean,
    hasMembershipAgreementSectionAmendmentOutstanding: boolean,
}) => {
    if (!hasMembershipAgreementSectionsOutstanding && !hasMembershipAgreementSectionAmendmentOutstanding) {
        return false;
    }

    return (
        <Row>
            <Col className="col-md-12">
                <Card className="card-accent-danger">
                    <CardHeader className="bg-danger">
                        <i className="fa fa-fw fa-exclamation-triangle"></i>
                        Outstanding Membership Agreement Sections
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col className="col-12">
                                {
                                    hasMembershipAgreementSectionsOutstanding
                                    && (
                                        <>
                                            <p>
                                                You must agree to all the Membership Agreement Sections <a
                                                href="/membership-agreement-sections">here</a>.
                                            </p>
                                        </>
                                    )
                                }

                                {
                                    hasMembershipAgreementSectionAmendmentOutstanding
                                    && (
                                        <>
                                            <p>
                                                You have an amendment(s) waiting to be approved.
                                            </p>
                                        </>
                                    )
                                }

                                {
                                    <p className="mt-3 mb-0">
                                        You will not be able to navigate the dashboard until all the Membership Agreement
                                        Sections have been accepted and any change(s) have been confirmed.
                                    </p>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default HasMembershipAgreementSectionsOutstanding