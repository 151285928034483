import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getApiErrorMessages } from '../helpers/apiErrorMessages';

class UserNotApprovedRestrictedComponent extends Component {
    render() {
        const { location, user } = this.props;

        if(
            user
            && !user.approved
            && location
            && location.pathname !== '/dashboard'
            && location.pathname !== '/membership-agreement-sections'
        ) {
            return <Redirect to="/dashboard" />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    const { error, token, user } = state.auth;
    const errors = getApiErrorMessages(error);

    return {
        errors,
        token,
        user,
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(UserNotApprovedRestrictedComponent)
);
