import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { noInternetConnectionNotification } from './helpers/toastNotification';
import KeysHeld from './views/Profile/KeysHeld';

// Profile lazies...
const Profile = () => {
    return (
        <Redirect to="/me/edit" />
    );
};
const EditProfile = lazy(() => retry(() => import('./views/Profile/EditProfile')));
const AddressProfile = lazy(() => retry(() => import('./views/Profile/AddressProfile')));
const BillingProfile = lazy(() => retry(() => import('./views/Profile/BillingProfile')));
const ChangePasswordProfile = lazy(() => retry(() => import('./views/Profile/ChangePasswordProfile')));
const SecuritySettings = lazy(() => retry(() => import('./views/Profile/SecuritySettings')));
const AdditionalDetailsProfile = lazy(() => retry(() => import('./views/Profile/AdditionalDetailsProfile')));
const Academy = lazy(() => retry(() => import('./views/Profile/Academy.js')));

// Dashboard lazies...
const Dashboard = lazy(() => retry(() => import('./views/Dashboard')));

// Appointments lazies...
const Appointments = lazy(() => retry(() => import('./views/Resources/Appointments/List')));
const AppointmentEdit = lazy(() => retry(() => import('./views/Resources/Appointments/Edit')));
const AppointmentChat = lazy(() => retry(() => import('./views/Resources/Appointments/Chat')));
const AppointmentShowAgreementToForm = lazy(() => retry(() => import('./views/Resources/Appointments/AgreementToForms/Show')));

// Invitations lazies...
const Invitations = lazy(() => retry(() => import('./views/Resources/Invitations/List')));
const InvitationEdit = lazy(() => retry(() => import('./views/Resources/Invitations/Edit')));

// LeaveTimes lazies...
const LeaveTimes = lazy(() => retry(() => import('./views/Resources/LeaveTimes/List')));
const CalendarIntegrations = lazy(() => retry(() => import('./views/Resources/LeaveTimes/Calendars/CalendarIntegration')));
const LeaveTimeCreate = lazy(() => retry(() => import('./views/Resources/LeaveTimes/Create')));
const LeaveTimeEdit = lazy(() => retry(() => import('./views/Resources/LeaveTimes/Edit')));
const RecurringLeaveTimeEdit = lazy(() => retry(() => import('./views/Resources/RecurringLeaveTimes/Edit')));

// Viewber Invoices lazies...
const ViewberInvoices = lazy(() => retry(() => import('./views/Resources/ViewberInvoices/List')));
const ViewberInvoiceCreate = lazy(() => retry(() => import('./views/Resources/ViewberInvoices/Create')));

// Viewbers Manual lazies...
const ViewbersManual = lazy(() => retry(() => import('./views/Resources/Documents/ViewbersManual.js')));
const RiskAssessmentForPropertyAssociatedRisks = lazy(() => retry(() => import('./views/Resources/Documents/RiskAssessmentForPropertyAssociatedRisks.js')));
const MembershipAgreementSections = lazy(() => retry(() => import('./views/MembershipAgreementSections/MembershipAgreementSections')));
const MembershipAgreementSectionsPdf = lazy(() => retry(() => import('./views/MembershipAgreementSections/MembershipAgreementSectionsPdf')));
const Guidance = lazy(() => retry(() => import('./views/Resources/Documents/Guidance.js')));

const Newsletter = lazy(() => retry(() => import('./views/Newsletter/Newsletter.tsx')));

// Wellbeing Support lazies...
const WellbeingSupport = lazy(() => retry(() => import('./views/Resources/WellbeingSupport/List')));

// Routes...
const routes = [
    { path: '/', exact: true, name: 'Home', component: Dashboard },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/appointments/:id', exact: true, name: 'Appointment Details', component: AppointmentEdit },
    { path: '/appointments/:id/chat', exact: true, name: 'Appointment Chat', component: AppointmentChat },
    { path: '/appointments/:id/agreement-to-forms/:formId', exact: true, name: 'Appointment Agreement.To Form', component: AppointmentShowAgreementToForm },
    { path: '/appointments', exact: true, name: 'Appointments', component: Appointments },
    { path: '/viewbers-manual', exact: true, name: 'Viewber Manual', component: ViewbersManual},
    { path: '/hazard-and-risk-assessment-for-property-associated-risks', exact: true, name: 'Hazard and Risk Assessment for Property Associated Risks', component: RiskAssessmentForPropertyAssociatedRisks},
    { path: '/membership-agreement-sections', exact: true, name: 'Membership Agreement Sections', component: MembershipAgreementSections},
    { path: '/membership-agreement-sections-pdf', exact: true, name: 'Membership Agreement Sections PDF', component: MembershipAgreementSectionsPdf},
    { path: '/guidance', exact: true, name: 'Guidance', component: Guidance},
    { path: '/invitations/:id', exact: true, name: 'Invitation Details', component: InvitationEdit },
    { path: '/invitations', exact: true, name: 'Invitations', component: Invitations },
    { path: '/invoices/create', exact: true, name: 'Submit Invoice', component: ViewberInvoiceCreate },
    { path: '/invoices', exact: true, name: 'Invoices', component: ViewberInvoices },
    { path: '/recurring-leave-times/:id', exact: true, name: 'Edit Unavailability', component: RecurringLeaveTimeEdit },
    { path: '/leave-times/:id', exact: true, name: 'Edit Unavailability', component: LeaveTimeEdit },
    { path: '/unavailability/create', exact: true, name: 'Add Unavailability', component: LeaveTimeCreate },
    { path: '/calendar', exact: true, name: 'My Calendar', component: LeaveTimes },
    { path: '/calendar/integration', exact: true, name: 'Calendar Integrations', component: CalendarIntegrations },
    { path: '/me/additional-details', exact: true, name: 'Additional Profile Details', component: AdditionalDetailsProfile },
    { path: '/me/address', exact: true, name: 'Address', component: AddressProfile },
    { path: '/me/keys-held', exact: true, name: 'Keys Held', component: KeysHeld },
    { path: '/me/billing', exact: true, name: 'Billing', component: BillingProfile },
    { path: '/me/edit', exact: true, name: 'Edit Profile', component: EditProfile },
    { path: '/me/academy', exact: true, name: 'Academy', component: Academy },
    { path: '/me/password', exact: true, name: 'Change Password', component: ChangePasswordProfile },
    { path: '/me/security', exact: true, name: 'Change Password', component: SecuritySettings },
    { path: '/me', exact: true, name: 'Profile', component: Profile },
    { path: '/newsletter', exact: true, name: 'Dashboard', component: Newsletter},
    { path: '/wellbeing-support', exact: true, name: 'Wellbeing Support', component: WellbeingSupport },
];

export default routes;

/**
 * Credits to https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
 */
const retry = (fn, retriesLeft = 5, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // maximum retries exceeded
                        noInternetConnectionNotification();
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
