import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';

class RestrictedComponent extends Component {
    componentDidMount() {
        // We scroll to top at the mounting of restricted component as it seems
        // React leaves the scroll down if we have scrolled down to a previous page.
        // This means the scroll to top is going to be quick (1ms),
        // and the user shouldn't be noticing it
        scroll.scrollToTop({
            smooth: false,
            duration: 1,
        });
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to="/login" />
        }

        const { location, user } = this.props;
        
        if(
            user
            && location
        ) {
            if (location.pathname === '/me/academy') {
                const ids = user?.viewber_types?.map(viewberType => viewberType.id);

                const hideAcademy = [
                    62,
                    63,
                    80,
                ].filter(num => ids.includes(num));

                if (hideAcademy.length > 0) {
                    return <Redirect to="/dashboard" />;
                }
            }

            if (
                user?.application_status_id === 4
                && user?.hasMembershipAgreementSectionsOutstanding
                && location.pathname !== '/membership-agreement-sections'
                && location.pathname !== '/dashboard'
            ) {
                return <Redirect to="/dashboard" />;
            }
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    const { token, user } = state.auth;

    return {
        user,
        loggedIn: (
            typeof token !== 'undefined'
            && token !== null
        )
    }
};

export default withRouter(
    connect(
        mapStateToProps
    )(RestrictedComponent)
);
